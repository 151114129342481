<script lang="ts">
import WordCard from "../components/WordCard.svelte";
import words from "../data/words";
import cards from "../data/cards";

let word = words.get(cards.all()[0].word_id);

let flipped = false;
let index = 1;
let progress = 0;
let total = 10;

async function right() {
    word = words.get(cards.all()[index].word_id);
    flipped = false;
    index++;
    progress++;
    if (index >= cards.all().length) {
        index = 0;
    }
}

const wrong = right;
</script>

<div class="lessons">
    {#if word}
        {#key word}
            <WordCard {word} {right} {wrong} {total} bind:progress bind:flipped />
        {/key}
    {/if}
</div>

<style>
.lessons {
    height: 100%;
}
</style>
