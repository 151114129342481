<script lang="ts">
import type { Lesson } from "../models/lesson";

export let completed: boolean = false;
export let lesson: Lesson;

function splitChineseTitle(title: string) {
    if (title.length === 4) {
        return title.slice(0, 2) + "<br/>" + title.slice(2);
    }
    return title;
}
</script>

<div class="lesson" class:completed>
    <div class="bubble">{@html splitChineseTitle(lesson.chinese_title)}</div>
    <div class="title">{lesson.title}</div>
</div>

<style>
.lesson {
    display: inline-block;
    cursor: pointer;
    width: 150px;
}

/*.lesson.locked {*/
/*    cursor: default;*/
/*}*/

.lesson.completed .bubble {
    background: #ffdd4a;
}

/*.lesson.locked .bubble {*/
/*    background: #cccccc;*/
/*}*/

/*.lesson.locked:hover .bubble {*/
/*    background: #cccccc;*/
/*}*/

.lesson .bubble {
    font-family: NotoSansSc, sans-serif;
    border-radius: 50%;
    background: #ed6a5a;
    color: white;
    font-weight: bold;
    font-size: 26px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
}

.lesson:hover .bubble {
    background: #d45e4f;
}

.lesson .title {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
</style>
