<script lang="ts">
export let color: string = "grey";
</script>

<div class="button test {color}">
    <slot />
</div>

<style lang="scss">
.button {
    background: #f3f3f3;
    box-shadow: 0 2px 0 #e3e3e3;
    height: 60px;
    border-radius: 5px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    font-weight: bold;
    color: #666666;

    &.red {
        background: #d45e4f;
        color: white;
    }

    &.green {
        background: #72b01d;
        color: white;
    }
}
</style>
