import lessons from "./lessons";
import words from "./words";
import cards from "./cards";
class App {
    async load() {
        await Promise.all([lessons.loading, words.loading, cards.loading]);
    }
}
const app = new App();
export default app;
