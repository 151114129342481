<script lang="ts">
import LessonBubble from "../components/LessonBubble.svelte";
import lessons from "../data/lessons";

let categories = lessons.categories();
</script>

<div class="lessons">
    {#each Object.entries(categories) as [category, lessons]}
        <div class="title">
            <h2>{category}</h2>
            <hr />
        </div>
        <div class="category-lessons">
            {#each lessons as lesson}
                <LessonBubble {lesson} />
            {/each}
        </div>
    {/each}
</div>

<style>
.lessons {
    padding: 30px;
}

.lessons .title {
    text-align: center;
}

.lessons .title h2 {
    display: inline-block;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    background: #ffffff;
    z-index: 1;
    color: #888888;
}
.lessons .title hr {
    position: relative;
    margin-top: -15px;
    margin-bottom: 45px;
    border: 1px solid #cccccc;
}

.lessons .category-lessons {
    gap: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
</style>
