/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "";
export var CardType;
(function (CardType) {
    CardType[CardType["WORD"] = 0] = "WORD";
    CardType[CardType["SENTENCE"] = 1] = "SENTENCE";
    CardType[CardType["SELECTION"] = 2] = "SELECTION";
    CardType[CardType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(CardType || (CardType = {}));
export function cardTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "WORD":
            return CardType.WORD;
        case 1:
        case "SENTENCE":
            return CardType.SENTENCE;
        case 2:
        case "SELECTION":
            return CardType.SELECTION;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CardType.UNRECOGNIZED;
    }
}
export function cardTypeToJSON(object) {
    switch (object) {
        case CardType.WORD:
            return "WORD";
        case CardType.SENTENCE:
            return "SENTENCE";
        case CardType.SELECTION:
            return "SELECTION";
        case CardType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseCard() {
    return { id: "", type: 0, lesson_id: "", word_id: undefined, sentence: [], valid_places: [], options: [] };
}
export const Card = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.type !== 0) {
            writer.uint32(16).int32(message.type);
        }
        if (message.lesson_id !== "") {
            writer.uint32(26).string(message.lesson_id);
        }
        if (message.word_id !== undefined) {
            writer.uint32(34).string(message.word_id);
        }
        for (const v of message.sentence) {
            writer.uint32(42).string(v);
        }
        writer.uint32(50).fork();
        for (const v of message.valid_places) {
            writer.uint32(v);
        }
        writer.ldelim();
        for (const v of message.options) {
            writer.uint32(58).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCard();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.lesson_id = reader.string();
                    break;
                case 4:
                    message.word_id = reader.string();
                    break;
                case 5:
                    message.sentence.push(reader.string());
                    break;
                case 6:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.valid_places.push(reader.uint32());
                        }
                    }
                    else {
                        message.valid_places.push(reader.uint32());
                    }
                    break;
                case 7:
                    message.options.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            type: isSet(object.type) ? cardTypeFromJSON(object.type) : 0,
            lesson_id: isSet(object.lesson_id) ? String(object.lesson_id) : "",
            word_id: isSet(object.word_id) ? String(object.word_id) : undefined,
            sentence: Array.isArray(object === null || object === void 0 ? void 0 : object.sentence) ? object.sentence.map((e) => String(e)) : [],
            valid_places: Array.isArray(object === null || object === void 0 ? void 0 : object.valid_places) ? object.valid_places.map((e) => Number(e)) : [],
            options: Array.isArray(object === null || object === void 0 ? void 0 : object.options) ? object.options.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.type !== undefined && (obj.type = cardTypeToJSON(message.type));
        message.lesson_id !== undefined && (obj.lesson_id = message.lesson_id);
        message.word_id !== undefined && (obj.word_id = message.word_id);
        if (message.sentence) {
            obj.sentence = message.sentence.map((e) => e);
        }
        else {
            obj.sentence = [];
        }
        if (message.valid_places) {
            obj.valid_places = message.valid_places.map((e) => Math.round(e));
        }
        else {
            obj.valid_places = [];
        }
        if (message.options) {
            obj.options = message.options.map((e) => e);
        }
        else {
            obj.options = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g;
        const message = createBaseCard();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.type = (_b = object.type) !== null && _b !== void 0 ? _b : 0;
        message.lesson_id = (_c = object.lesson_id) !== null && _c !== void 0 ? _c : "";
        message.word_id = (_d = object.word_id) !== null && _d !== void 0 ? _d : undefined;
        message.sentence = ((_e = object.sentence) === null || _e === void 0 ? void 0 : _e.map((e) => e)) || [];
        message.valid_places = ((_f = object.valid_places) === null || _f === void 0 ? void 0 : _f.map((e) => e)) || [];
        message.options = ((_g = object.options) === null || _g === void 0 ? void 0 : _g.map((e) => e)) || [];
        return message;
    },
};
function createBaseCards() {
    return { cards: [] };
}
export const Cards = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.cards) {
            Card.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCards();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cards.push(Card.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { cards: Array.isArray(object === null || object === void 0 ? void 0 : object.cards) ? object.cards.map((e) => Card.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.cards) {
            obj.cards = message.cards.map((e) => e ? Card.toJSON(e) : undefined);
        }
        else {
            obj.cards = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a;
        const message = createBaseCards();
        message.cards = ((_a = object.cards) === null || _a === void 0 ? void 0 : _a.map((e) => Card.fromPartial(e))) || [];
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
