import { Cards } from "../proto/card";
const CARDS_URL = "/materials/cards.bin";
class CardsData {
    constructor() {
        this.loading = this.getCards();
    }
    async getCards() {
        const res = await fetch(CARDS_URL);
        const buffer = await res.arrayBuffer();
        this.cards = Cards.decode(new Uint8Array(buffer)).cards;
        this.cardsById = Object.assign({}, ...this.cards.map((x) => ({ [x.id]: x })));
    }
    get(id) {
        return this.cardsById[id];
    }
    all() {
        return this.cards;
    }
}
const cards = new CardsData();
export default cards;
