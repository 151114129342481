<script lang="ts">
export let progress: number | undefined = undefined;
export let total: number | undefined = 10;
export let count: number | undefined = 5;
export let showCount: boolean = true;

$: progressWidth = progress ? progress : (count / total) * 100;
</script>

<div class="outer">
    <div class="bar">
        <div style="width: {progressWidth}%" />
    </div>
    {#if count !== undefined && showCount}
        <div class="count">
            {count} / {total}
        </div>
    {/if}
</div>

<style lang="scss">
.outer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.bar {
    flex: 1;
    height: 10px;
    background: #cccccc;
    border-radius: 5px;
    overflow: hidden;

    div {
        height: 10px;
        background: #0087f4;
        border-radius: 5px;
    }
}

.count {
    font-weight: bold;
    margin: 0 0 0 10px;
}
</style>
