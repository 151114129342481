<script lang="ts">
import Button from "./Button.svelte";

export let options: string[];
export let answer: string;

export let select = (i: number) => {};

let selected: number | undefined = undefined;

function selectAnswer(i: number) {
    if (selected !== undefined) {
        return;
    }

    selected = i;
    select(i);
}

$: getColor = function (i: number) {
    if (selected !== i) {
        return undefined;
    }

    return options[i] === answer ? "green" : "red";
};
</script>

<div class="multiple-choice">
    {#each options as option, i}
        <div class:right={option === answer} class:selected={selected === i} on:click={() => selectAnswer(i)}>
            <Button color={getColor(i)}>
                {option}
            </Button>
        </div>
    {/each}
</div>

<style lang="scss">
.multiple-choice {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}
</style>
