<script lang="ts">
import api from "../api/client";
import { navigate } from "svelte-routing";

const lessons = {};

export let id = 0;

const lesson = lessons[id];

function markLessonDone() {
    api.completeLessons(id);
    navigate("/");
}
</script>

<div class="lesson">
    <h1>{lesson.title}</h1>
    {#each lesson.sections as section}
        <p>{@html section}</p>
    {/each}

    <div class="button" on:click={markLessonDone}>Done</div>
</div>

<style lang="sass">
.lesson
    background: white
    max-width: 800px
    margin: 0 auto
    padding: 30px
    height: 100%

    h1
        margin: 0

.button
    margin-top: 20px
    background: #d45e4f
    padding: 10px
    border-radius: 10px
    width: fit-content
    color: white

</style>
