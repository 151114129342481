<script lang="ts">
import type { Word } from "../proto/word";
import { wordTypeMap } from "../helpers/wordtypes";

export let word: Word;

function highlight(input: string): string {
    return input.replace(/_([^_]*)_/g, '<span class="highlight">$1</span>');
}
</script>

<div class="meaning">
    {#each word.meanings as meaning, i}
        {#if meaning.word_type}
            <span class="index">{i + 1}</span>
            <span class="word-type">{wordTypeMap[meaning.word_type]}</span>
        {/if}
        {meaning.english}
    {/each}
</div>

{#if word.sentences.length >= 1}
    <div class="sentences">
        <hr />
        {#each word.sentences as sentence}
            <div class="sentence">
                <div class="chinese">{@html highlight(sentence.chinese)}</div>
                <div class="english">{@html highlight(sentence.english)}</div>
            </div>
        {/each}
    </div>
{/if}

<style lang="scss">
.meaning {
    font-size: 30px;

    .index {
        color: #777777;
        display: inline-block;
        border: 2px solid #777777;
        font-weight: bold;
        font-size: 20px;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 10px;
    }

    .word-type {
        margin-right: 10px;
        color: #777777;
    }
}

hr {
    margin: 20px 0;
    border: 1px solid #cccccc;
}

.sentence {
    padding: 0 100px;
}

.sentence .english {
    font-style: italic;
    color: #666666;
    font-size: 16px;
}
</style>
