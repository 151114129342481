import { Words } from "../proto/word";
const WORDS_URL = "/materials/words.bin";
class WordsData {
    constructor() {
        this.loading = this.getWords();
    }
    async getWords() {
        const res = await fetch(WORDS_URL);
        const buffer = await res.arrayBuffer();
        const wordArr = Words.decode(new Uint8Array(buffer)).words;
        this.words = Object.assign({}, ...wordArr.map((x) => ({ [x.id]: x })));
    }
    get(id) {
        return this.words[id];
    }
}
const words = new WordsData();
export default words;
