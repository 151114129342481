export function group(arr, key) {
    return arr.reduce(function (out, item) {
        const itemKey = item[key];
        if (out[itemKey]) {
            out[itemKey].push(item);
        }
        else {
            out[itemKey] = [item];
        }
        return out;
    }, {});
}
