/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "";
function createBaseLesson() {
    return { id: "", title: "", chinese_title: "", contents: "", order: 0, category: "", words: [] };
}
export const Lesson = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        if (message.chinese_title !== "") {
            writer.uint32(26).string(message.chinese_title);
        }
        if (message.contents !== "") {
            writer.uint32(34).string(message.contents);
        }
        if (message.order !== 0) {
            writer.uint32(40).uint32(message.order);
        }
        if (message.category !== "") {
            writer.uint32(50).string(message.category);
        }
        for (const v of message.words) {
            writer.uint32(58).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLesson();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.chinese_title = reader.string();
                    break;
                case 4:
                    message.contents = reader.string();
                    break;
                case 5:
                    message.order = reader.uint32();
                    break;
                case 6:
                    message.category = reader.string();
                    break;
                case 7:
                    message.words.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            title: isSet(object.title) ? String(object.title) : "",
            chinese_title: isSet(object.chinese_title) ? String(object.chinese_title) : "",
            contents: isSet(object.contents) ? String(object.contents) : "",
            order: isSet(object.order) ? Number(object.order) : 0,
            category: isSet(object.category) ? String(object.category) : "",
            words: Array.isArray(object === null || object === void 0 ? void 0 : object.words) ? object.words.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.title !== undefined && (obj.title = message.title);
        message.chinese_title !== undefined && (obj.chinese_title = message.chinese_title);
        message.contents !== undefined && (obj.contents = message.contents);
        message.order !== undefined && (obj.order = Math.round(message.order));
        message.category !== undefined && (obj.category = message.category);
        if (message.words) {
            obj.words = message.words.map((e) => e);
        }
        else {
            obj.words = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f, _g;
        const message = createBaseLesson();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.title = (_b = object.title) !== null && _b !== void 0 ? _b : "";
        message.chinese_title = (_c = object.chinese_title) !== null && _c !== void 0 ? _c : "";
        message.contents = (_d = object.contents) !== null && _d !== void 0 ? _d : "";
        message.order = (_e = object.order) !== null && _e !== void 0 ? _e : 0;
        message.category = (_f = object.category) !== null && _f !== void 0 ? _f : "";
        message.words = ((_g = object.words) === null || _g === void 0 ? void 0 : _g.map((e) => e)) || [];
        return message;
    },
};
function createBaseLessons() {
    return { lessons: [] };
}
export const Lessons = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.lessons) {
            Lesson.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLessons();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lessons.push(Lesson.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { lessons: Array.isArray(object === null || object === void 0 ? void 0 : object.lessons) ? object.lessons.map((e) => Lesson.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.lessons) {
            obj.lessons = message.lessons.map((e) => e ? Lesson.toJSON(e) : undefined);
        }
        else {
            obj.lessons = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a;
        const message = createBaseLessons();
        message.lessons = ((_a = object.lessons) === null || _a === void 0 ? void 0 : _a.map((e) => Lesson.fromPartial(e))) || [];
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
