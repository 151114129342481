<script lang="ts">
</script>

<main>
    <h1>Sign In</h1>
    <hr />
    <a href="/api/login?method=google">
        <div class="google-button">
            <img
                class="left"
                alt="Google Logo"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
            />
            Sign in with Google
        </div>
    </a>
    <!--<a href="/api/login?method=apple">-->
    <div class="apple-button">
        <img
            class="left"
            alt="Google Logo"
            src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
        />
        Sign in with Apple
    </div>
    <!--    </a>-->
</main>

<style>
main {
    width: 400px;
    background: white;
    padding: 30px;
    border-radius: 5px;
    margin: 50px auto;
}

main h1 {
    color: #0a122a;
    margin: 0 0 20px 0;
}

main hr {
    border: 0;
    border-top: 1px solid #dddddd;
    margin: 20px 0;
}

.google-button {
    border: 1px solid #dddddd;
    padding: 5px;
    vertical-align: middle;
    font-weight: normal;
    line-height: 30px;
    font-size: 20px;
    border-radius: 5px;
    color: #444444;
}

.google-button img {
    float: left;
    width: 20px;
    margin: 5px 10px 0 5px;
}

.apple-button {
    margin-top: 20px;
    padding: 5px;
    vertical-align: middle;
    font-weight: normal;
    line-height: 32px;
    font-size: 20px;
    border-radius: 5px;
    color: #ffffff;
    background: #333333;
}

.apple-button img {
    float: left;
    width: 20px;
    margin: 3px 10px 0 5px;
}
</style>
