import { Lessons } from "../proto/lesson";
import { group } from "../helpers/group";
const LESSONS_URL = "/materials/lessons.bin";
class LessonsData {
    constructor() {
        this.loading = this.getLessons();
    }
    async getLessons() {
        const res = await fetch(LESSONS_URL);
        const buffer = await res.arrayBuffer();
        this.lessons = Lessons.decode(new Uint8Array(buffer)).lessons;
        this.lessonsById = Object.assign({}, ...this.lessons.map((x) => ({ [x.id]: x })));
        this.lessonsByCategory = group(this.lessons, "category");
    }
    get(id) {
        return this.lessonsById[id];
    }
    all() {
        return this.lessons;
    }
    categories() {
        return this.lessonsByCategory;
    }
}
const lessons = new LessonsData();
export default lessons;
