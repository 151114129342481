import { writable } from "svelte/store";
import completedLessons from "../stores/completed-lessons";
const BASE_URL = "https://kuaichinese.jochemligtenberg.com/";
class ApiClient {
    constructor(baseUrl) {
        this.cache = {};
        this.baseUrl = baseUrl || BASE_URL;
    }
    async refresh() {
        const response = await fetch("/api/refresh", { method: "POST" });
        return response.ok;
    }
    async request(method, path, body, retry = false) {
        const response = await fetch(path, { method: method, body: JSON.stringify(body) });
        console.log(`Hello ${path}`);
        console.log(response);
        if (!response.ok) {
            if (response.status === 401) {
                if (!retry && (await this.refresh())) {
                    return this.request(method, path, body, true);
                }
                window.location.href = "/login";
            }
            return undefined;
        }
        return response.json();
    }
    async me() {
        return this.request("GET", "/api/me");
    }
    async lessons() {
        if (this.cache.completedLessons !== undefined) {
            return this.cache.completedLessons;
        }
        const response = await this.request("GET", "/api/user-data/lessons");
        completedLessons.set(response.completed);
        this.cache.completedLessons = writable(response);
        return this.cache.completedLessons;
    }
    async completeLessons(...lessonIds) {
        const response = await this.request("PATCH", "/api/user-data/lessons", {
            completed: lessonIds,
        });
        completedLessons.set(response.completed);
        this.cache.completedLessons = writable(response);
        return this.cache.completedLessons;
    }
}
const api = new ApiClient();
export default api;
