/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
export const protobufPackage = "";
function createBaseSentence() {
    return { english: "", chinese: "" };
}
export const Sentence = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.english !== "") {
            writer.uint32(10).string(message.english);
        }
        if (message.chinese !== "") {
            writer.uint32(18).string(message.chinese);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSentence();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.english = reader.string();
                    break;
                case 2:
                    message.chinese = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            english: isSet(object.english) ? String(object.english) : "",
            chinese: isSet(object.chinese) ? String(object.chinese) : "",
        };
    },
    toJSON(message) {
        const obj = {};
        message.english !== undefined && (obj.english = message.english);
        message.chinese !== undefined && (obj.chinese = message.chinese);
        return obj;
    },
    fromPartial(object) {
        var _a, _b;
        const message = createBaseSentence();
        message.english = (_a = object.english) !== null && _a !== void 0 ? _a : "";
        message.chinese = (_b = object.chinese) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function createBaseMeaning() {
    return { english: "", chinese: undefined, word_type: undefined };
}
export const Meaning = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.english !== "") {
            writer.uint32(10).string(message.english);
        }
        if (message.chinese !== undefined) {
            writer.uint32(18).string(message.chinese);
        }
        if (message.word_type !== undefined) {
            writer.uint32(26).string(message.word_type);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMeaning();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.english = reader.string();
                    break;
                case 2:
                    message.chinese = reader.string();
                    break;
                case 3:
                    message.word_type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            english: isSet(object.english) ? String(object.english) : "",
            chinese: isSet(object.chinese) ? String(object.chinese) : undefined,
            word_type: isSet(object.word_type) ? String(object.word_type) : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.english !== undefined && (obj.english = message.english);
        message.chinese !== undefined && (obj.chinese = message.chinese);
        message.word_type !== undefined && (obj.word_type = message.word_type);
        return obj;
    },
    fromPartial(object) {
        var _a, _b, _c;
        const message = createBaseMeaning();
        message.english = (_a = object.english) !== null && _a !== void 0 ? _a : "";
        message.chinese = (_b = object.chinese) !== null && _b !== void 0 ? _b : undefined;
        message.word_type = (_c = object.word_type) !== null && _c !== void 0 ? _c : undefined;
        return message;
    },
};
function createBaseWord() {
    return { id: "", word: "", pinyin: "", hsk: 0, meanings: [], sentences: [] };
}
export const Word = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.word !== "") {
            writer.uint32(18).string(message.word);
        }
        if (message.pinyin !== "") {
            writer.uint32(26).string(message.pinyin);
        }
        if (message.hsk !== 0) {
            writer.uint32(32).int32(message.hsk);
        }
        for (const v of message.meanings) {
            Meaning.encode(v, writer.uint32(42).fork()).ldelim();
        }
        for (const v of message.sentences) {
            Sentence.encode(v, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.word = reader.string();
                    break;
                case 3:
                    message.pinyin = reader.string();
                    break;
                case 4:
                    message.hsk = reader.int32();
                    break;
                case 5:
                    message.meanings.push(Meaning.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.sentences.push(Sentence.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            word: isSet(object.word) ? String(object.word) : "",
            pinyin: isSet(object.pinyin) ? String(object.pinyin) : "",
            hsk: isSet(object.hsk) ? Number(object.hsk) : 0,
            meanings: Array.isArray(object === null || object === void 0 ? void 0 : object.meanings) ? object.meanings.map((e) => Meaning.fromJSON(e)) : [],
            sentences: Array.isArray(object === null || object === void 0 ? void 0 : object.sentences) ? object.sentences.map((e) => Sentence.fromJSON(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.word !== undefined && (obj.word = message.word);
        message.pinyin !== undefined && (obj.pinyin = message.pinyin);
        message.hsk !== undefined && (obj.hsk = Math.round(message.hsk));
        if (message.meanings) {
            obj.meanings = message.meanings.map((e) => e ? Meaning.toJSON(e) : undefined);
        }
        else {
            obj.meanings = [];
        }
        if (message.sentences) {
            obj.sentences = message.sentences.map((e) => e ? Sentence.toJSON(e) : undefined);
        }
        else {
            obj.sentences = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a, _b, _c, _d, _e, _f;
        const message = createBaseWord();
        message.id = (_a = object.id) !== null && _a !== void 0 ? _a : "";
        message.word = (_b = object.word) !== null && _b !== void 0 ? _b : "";
        message.pinyin = (_c = object.pinyin) !== null && _c !== void 0 ? _c : "";
        message.hsk = (_d = object.hsk) !== null && _d !== void 0 ? _d : 0;
        message.meanings = ((_e = object.meanings) === null || _e === void 0 ? void 0 : _e.map((e) => Meaning.fromPartial(e))) || [];
        message.sentences = ((_f = object.sentences) === null || _f === void 0 ? void 0 : _f.map((e) => Sentence.fromPartial(e))) || [];
        return message;
    },
};
function createBaseWords() {
    return { words: [] };
}
export const Words = {
    encode(message, writer = _m0.Writer.create()) {
        for (const v of message.words) {
            Word.encode(v, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseWords();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.words.push(Word.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return { words: Array.isArray(object === null || object === void 0 ? void 0 : object.words) ? object.words.map((e) => Word.fromJSON(e)) : [] };
    },
    toJSON(message) {
        const obj = {};
        if (message.words) {
            obj.words = message.words.map((e) => e ? Word.toJSON(e) : undefined);
        }
        else {
            obj.words = [];
        }
        return obj;
    },
    fromPartial(object) {
        var _a;
        const message = createBaseWords();
        message.words = ((_a = object.words) === null || _a === void 0 ? void 0 : _a.map((e) => Word.fromPartial(e))) || [];
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
