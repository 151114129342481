<script lang="ts">
import { Router, Link, Route } from "svelte-routing";
import Home from "./routes/Home.svelte";
import Lesson from "./routes/Lesson.svelte";
import Login from "./routes/Login.svelte";
import Exercise from "./routes/Exercise.svelte";
import Account from "./routes/Account.svelte";
import Progress from "./routes/Progress.svelte";
import app from "./data/app";

export let url = "";
</script>

{#await app.load()}
    <h1>Loading</h1>
{:then _}
    <Router {url}>
        <!--        <Link to="/">-->
        <!--            <img class="logo" alt="logo" src="/logo-full.svg" />-->
        <!--        </Link>-->
        <!--        <header>-->
        <!--            <Link to="/">-->
        <!--                <div>Practice</div>-->
        <!--            </Link>-->
        <!--            <Link to="/progress">-->
        <!--                <div>Progress</div>-->
        <!--            </Link>-->
        <!--            <Link to="/account">-->
        <!--                <div>Account</div>-->
        <!--            </Link>-->
        <!--        </header>-->
        <main>
            <Route path="account" component={Account} />
            <Route path="progress" component={Progress} />
            <Route path="login" component={Login} />
            <Route path="lessons/:id" component={Lesson} />
            <Route path="exercises/:id" component={Exercise} />
            <Route path="/"><Home /></Route>
        </main>
    </Router>
{:catch err}
    <h1>Failed to load</h1>
    {err}
{/await}

<style>
.logo {
    display: block;
    margin: 20px auto 0 auto;
    width: 500px;
    max-width: 100%;
    vertical-align: middle;
}

header {
    color: white;
    max-width: 600px;
    margin: -20px auto 0 auto;
    text-align: right;
    font-weight: bold;
    padding: 10px;
}

header div {
    margin-left: 20px;
    display: inline-block;
}

main {
    max-width: 660px;
    background: white;
    margin: 0 auto;
    min-height: 500px;
    color: #0a122a;
    /*border-bottom: 3px solid #bbbbbb;*/
    height: 100%;
}
</style>
