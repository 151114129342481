<script lang="ts">

    const exercise = {
        title: "Test",
        sentence: ["我", "是", "学生"],
        word: "不",
        valid_places: [1]
    }

</script>

<div class="question">
    <h1>{exercise.title}</h1>

    <div class="sentence">
        {#each exercise.sentence as word, i}
            <div class="empty" class:right={exercise.valid_places.includes(i)}></div>
            <div class="word">{word}</div>
        {/each}
        <div class="empty" class:right={exercise.valid_places.includes(exercise.sentence.length)}></div>
    </div>

    <div class="target-word" draggable="true">
        {exercise.word}
    </div>
</div>


<style lang="sass">
    .question
        color: black

    .sentence
        width: fit-content
        margin: 0 auto
        font-size: 25px
        line-height: 25px

        .word
            display: inline

        .empty
            display: inline-block
            width: 25px
            background: #EEEEEE
            height: 25px
            vertical-align: middle
            border: 3px dashed #ddd
            border-radius: 5px
            margin: 0 10px

            &:hover
                background: #FFDD4A
                border-color: #C9AC07
                &.right
                    background: #70A134
                    border-color: #4F8110

    .target-word
        text-align: center
        margin: 20px 0
        font-size: 25px
        cursor: move

</style>