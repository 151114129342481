<script type="ts">
import type { Word } from "../proto/word";
import MultipleChoice from "./MultipleChoice.svelte";
import Button from "./Button.svelte";
import WordDescription from "./WordDescription.svelte";
import ProgressBar from "./ProgressBar.svelte";

export let flipped = false;
export let progress = 0;
export let total = 0;
export let word: Word;
export let wrong = (): void => console.log("Wrong!");
export let right = (): void => console.log("Right!");

$: answer = word.meanings[0].english;
$: options = shuffleOptions(answer);

function getRandomSubarray(arr, size, answer) {
    const indexOfAnswer = arr.indexOf(answer);
    if (indexOfAnswer > -1) {
        arr.splice(indexOfAnswer, 1);
    }
    const shuffled = arr.slice(0);
    for (let i = arr.length - 1; i >= 0; i--) {
        const index = Math.floor((i + 1) * Math.random());
        [shuffled[index], shuffled[i]] = [shuffled[i], shuffled[index]];
    }
    return shuffled.slice(0, size);
}

function shuffleOptions(answer: string[]) {
    const answers = ["He", "She", "I", "You", "They", "We"];
    const out = getRandomSubarray(answers, 3, answer);
    const index = Math.floor(Math.random() * 4);
    out.splice(index, 0, answer);
    return out;
}

const type: number = Math.floor(Math.random() * 2);

function flip() {
    flipped = true;
}

let wasRight = undefined;
function next() {
    if (wasRight) {
        right();
    } else {
        wrong();
    }
}

function select(selection?: number): void {
    flip();
    wasRight = options[selection] === answer;
}
</script>

<div class="card">
    <div class="target-word">
        <div class="question">What does this word mean?</div>
        <div class="spacer" />
        <div class="word">
            <div class="chinese">{word.word}</div>
            {#if flipped}
                <div class="pinyin">{word.pinyin}</div>
            {/if}
        </div>
        <div class="spacer" />
    </div>
    <div class="middle">
        <ProgressBar count={progress} {total} />
        {#if flipped}
            <WordDescription {word} />
        {/if}
    </div>
    <div class="bottom">
        {#if type === 0}
            {#if flipped}
                <div class="next" on:click={next}>
                    <div><Button>Next</Button></div>
                </div>
            {/if}
            <MultipleChoice {options} {answer} {select} />
        {:else if type === 1}
            {#if !flipped}
                <div on:click={flip}><Button>Reveal</Button></div>
            {:else}
                <div class="buttons">
                    <div on:click={wrong}><Button color="red">Wrong</Button></div>
                    <div on:click={right}><Button color="green">Right</Button></div>
                </div>
            {/if}
        {/if}
    </div>
</div>

<style lang="scss">
.card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.middle {
    flex-grow: 1;
    padding: 20px;
}

.target-word {
    display: flex;
    flex-direction: column;

    border-radius: 0 0 30px 30px;
    background: #ed6a5a;
    box-shadow: 0 4px 0 #c0473b;
    height: 300px;
    max-height: 30vh;
    text-align: center;
    color: #ffffff;

    .question {
        padding-top: 30px;
        font-weight: bold;
        font-size: 20px;
    }

    .spacer {
        flex: 1;
    }

    .word {
        height: 105px;
        font-size: 50px;
    }

    .pinyin {
        font-size: 24px;
    }
}

.bottom {
    padding: 30px 20px;
    font-size: 20px;
    position: relative;

    .next {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6);

        div {
            margin: 0 auto;
            width: 75%;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
        }
    }
}

.buttons {
    display: flex;
    gap: 20px;

    div {
        flex: 1;
    }
}
</style>
