<script lang="ts">
import Button from "../components/Button.svelte";
import client from "../api/client";

const mePromise = client.me();
</script>

<main>
    {#await mePromise}
        Loading
    {:then me}
        <b>ID:</b>
        {me.id} <br />
        <b>Email:</b>
        {me.email} <br /><br />
        {#if !me.subscribed}
            <a href="/api/payments/checkout/create">
                <Button>Subscribe</Button>
            </a>
        {:else}
            <a href="/api/payments/customer-portal">
                <Button>Subscriptions</Button>
            </a>
        {/if}
    {/await}
</main>

<style>
main {
    padding: 30px;
}
</style>
